@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

#page-recursos{
    width: 100%;
    scroll-behavior: smooth;

    // #section-recursos-introduct{
    //     height: 100vh;
    //     width: 100%;
    //     // background-color: red;
    //     display: flex;
    //     align-items: center;
    //     justify-content: space-around;
    //     padding: 0 7.5%;
    //     // background: rgb(245,245,245);
    //     // background: linear-gradient(180deg, rgba(245,245,245,1) 0%, rgba(245,245,245,1) 60%, rgba(255,255,255,1) 100%);
    //     background: rgb(245,245,245);
    //     background: linear-gradient(180deg, rgba(245,245,245,1) 0%, rgba(245,245,245,1) 60%, rgba(250,250,250,1) 100%);

    //     >div{
    //         width: 65%;
    //         padding-left: 5%;
    //         display: flex;
    //         flex-direction: column;
    //         align-items: flex-start;

    //         h1{
    //             font-size: 3.5em;
    //             line-height: 1.25;
    //             font-weight: 600;
    //             margin-bottom: .5em;
    //             color: #333333;
    
    //             span{
    //                 font-weight: 700;
    //                 color: #0071ae;
    //             }
    //         }

    //         h4{
    //             font-weight: 400;
    //             opacity: .8;
    //             margin-bottom: 2em;
    //         }

    //         >button{
    //             padding: .8em 2em;
    //             background-color: #0071ae;
    //             color: #fff !important;
    //             border-radius: 50px;
    //             transition: 0.3s ease all;
    //             font-size: .85em;

    //             span{
    //                 margin-left: .5em;
    //                 top: 0;
    //                 transition: .3s ease all;
    //                 position: relative;
    //             }

    //             &:hover{
    //                 background-color: #006094;
    //                 color: #fff;

    //                 span{
    //                     top: .3em;
    //                 }
    //             }
    //         }
    //     }

    //     img{
    //         width: 20%;
    //         transform: rotate(-20deg);
    //     }
    // }

    #section-recursos-introduct{
        width: 100%;
        height: 100vh;
        padding: 15vh 7.5%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        h1{
            font-size: 3em;
            line-height: 1.25;
            font-weight: 600;
            margin: 10vh 0 .5em;
            color: #333333;
            text-align: center;

            span{
                font-weight: 700;
                color: #0071ae;
            }
        }

        h4{
            font-size: 1.15em;
            font-weight: 400;
            opacity: .8;
            margin-bottom: 4em;
            text-align: center;
        }

        ul{
            width: 70%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            row-gap: 1em;

            li{
                width: calc(100% / 3.2);
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 2em 1em;
                background-color: #f5f5f5;

                svg{
                    font-size: 2.5em;
                    color: #006094;
                    margin-bottom: 0.5em;
                }

                h3{
                    margin-bottom: 0em;
                    font-size: 1em;
                    opacity: 1;
                    font-weight: 600;
                }
            }
        }

        button{
            margin-top: 8vh;
            padding: 0.6em 2em;
            background-color: #0071ae;
            color: #fff !important;
            border-radius: 50px;
            transition: 0.3s ease all;
            font-size: 0.85em;
            z-index: 2;

            span{
                margin-left: .5em;
                top: 0;
                transition: .3s ease all;
                position: relative;
            }

            &:hover{
                background-color: #006094;
                color: #fff;

                span{
                    top: .2em;
                }
            }
        }
    }

    section{
        &:nth-child(even){
            background-color: #f5f5f5;
        }

        .content-section-recursos{
            width: 40%;

            h2{
                font-size: 2em;
                color: #0071ae;
                margin-bottom: .6em;
            }

            h3{
                font-size: .95em;
                text-align: justify;
            }
        }
    }

    #section-recursos-agendamentos, #section-recursos-gestao, #section-recursos-agenda, #section-recursos-relatorios, #section-recursos-financeiro{
        padding: 15vh 10vw;
        display: flex;
        justify-content: space-between;
        align-items: center;
        
        img{
            width: 50%;
            border-radius: 4px;
            box-shadow: 0px 8px 24px 0 rgba(29, 140, 242, 0.3);
        }
    }
}

@media screen and (max-width: 1250px) {
    #page-recursos {
        #section-recursos-introduct {
            ul {
                width: 80%;
            }
        }

        section{
            .content-section-recursos{
                width: 45%;
            }
        }

        #section-recursos-agendamentos, #section-recursos-gestao, #section-recursos-agenda, #section-recursos-relatorios, #section-recursos-financeiro{
            padding: 15vh 5vw;
        }
    }
}

@media screen and (max-width: 950px) {
    #page-recursos {
        section:not(#section-recursos-introduct){
            &:nth-child(odd){
                flex-direction: column-reverse !important;
            }
        }

        #section-recursos-agendamentos, #section-recursos-gestao, #section-recursos-agenda, #section-recursos-relatorios, #section-recursos-financeiro{
            padding: 10vh 5vw;
            flex-direction: column;

            img{
                width: 60%;
                margin-bottom: 7vh;
            }

            .content-section-recursos{
                width: 90%;

                h3{
                    text-align: center;
                }
            }
        }
    }
}

@media screen and (max-width: 850px) {
    #page-recursos {
        #section-recursos-introduct {
            ul {
                width: 100%;
            }
        }
    }
}

@media screen and (max-width: 750px) {
    #page-recursos {
        #section-recursos-introduct {
            padding: 15vh 5%;

            h1{
                font-size: 2.7em;
                line-height: 1.1;
            }

            h4{
                font-size: 1.05em;
            }

            ul{
                li{
                    h3{
                        font-size: .9em;
                    }
                }
            }
        }

        #section-recursos-agendamentos, #section-recursos-gestao, #section-recursos-agenda, #section-recursos-relatorios, #section-recursos-financeiro{
            img{
                width: 70%;
            }
        }

        .section-cta{
            h2{
                font-size: 1.8em !important;
            }

            h3{
                font-size: .95em !important;
            }
        }
    }
}

@media screen and (max-width: 650px) {
    #page-recursos {
        #section-recursos-introduct {
            h1{
                font-size: 2.5em;
            }
        }

        #section-recursos-agendamentos, #section-recursos-gestao, #section-recursos-agenda, #section-recursos-relatorios, #section-recursos-financeiro{
            img{
                width: 80%;
            }

            .content-section-recursos{
                h2{
                    font-size: 1.8em;
                }
                
                h3{
                    font-size: .85em;
                }
            }
        }
    }
}

@media screen and (max-width: 550px) {
    #page-recursos {
        #section-recursos-introduct {
            ul{
                justify-content: space-between;

                li{
                    width: calc(100% / 3.1);

                    h3{
                        font-size: .8em;
                    }
                }
            }
        }

        #section-recursos-agendamentos, #section-recursos-gestao, #section-recursos-agenda, #section-recursos-relatorios, #section-recursos-financeiro{
            img{
                width: 90%;
            }

            .content-section-recursos{
                h2{
                    font-size: 1.7em;
                }
            }
        }

        .section-cta{
            h2{
                font-size: 1.7em !important;
            }

            h3{
                font-size: .95em !important;
            }
        }
    }
}