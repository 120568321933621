#page-planos{
    width: 100%;

    #section-planos-planos{
        width: 100%;
        padding: 15vh 10vw;
        display: flex;
        flex-direction: column;
        align-items: center;

        h1{
            font-size: 3em;
            line-height: 1.25;
            font-weight: 600;
            margin: 10vh 0 .5em;
            color: #333333;
            text-align: center;

            span{
                font-weight: 700;
                color: #0071ae;
            }
        }

        h4{
            font-size: 1.15em;
            font-weight: 400;
            opacity: .8;
            margin-bottom: 5em;
            text-align: center;
        }

        .type-billing-plan{
            margin-bottom: 1.5em;
            display: flex;
            border-radius: 100px;
            user-select: none;

            >div{
                width: 10em;
                // background-color: #d5d5d5;
                border: 3px solid #8ad8e6;
                padding: .2em .5em;
                text-align: center;
                cursor: pointer;
                color: #333333;

                &:first-of-type{
                    border-radius: 100px 0 0 100px;
                    border-right: 0;
                }

                &:last-of-type{
                    border-radius: 0 100px 100px 0;
                    border-left: 0;
                }

                &.billing-plan-active{
                    background-color: #8ad8e6;
                    cursor: auto;
                }
            }
        }

        ul{
            margin-top: 3em;
            // width: 90%;
            // min-width: 900px;
            // width: 60%;
            // min-width: 700px;
            width: 90%;
            // display: grid;
            // grid-template-columns: repeat(2, 1fr);
            // grid-template-rows: 1fr;
            grid-column-gap: 3em;
            display: flex;

            li{
                width: calc(100% - 3em / 2);
                padding: 1em .5em;
                border: 1px solid #d5d5d5;
                border-radius: 8px;
                transition: all .5s ease;
                display: flex;
                flex-direction: column;
                align-items: center;
                position: relative;

                &:hover{
                    box-shadow: 0px 4px 24px 0 rgba(29, 140, 242, 0.16);
                    transform: scale(1.05);
                }

                &.plan-destaque{
                    border: 3px solid #0071ae;
                    transform: scale(1.1);

                    &:hover{
                        transform: scale(1.15);
                    }
                }

                .card-plan{
                    top: -1em;
                    position: absolute;
                    background-color: #0071ae;
                    padding: .2em 0;
                    width: 50%;
                    text-align: center;
                    color: #fff;
                    border-radius: 5px;
                    font-size: .8em;
                }

                h5{
                    margin-bottom: .5em;
                    font-size: 1.2em;
                }

                h6{
                    font-size: 2em;
                    font-weight: 600;
                    color: #333333;

                    span{
                        font-weight: 300;
                        font-size: .6em;
                    }
                }

                >p{
                    font-size: .8em;
                }

                a{
                    margin-top: 2em;
                    padding: .4em 2em;
                    background-color: #0071ae;
                    color: #fff !important;
                    border-radius: 50px;
                    transition: 0.3s ease all;
                    font-size: .85em;

                    &:hover{
                        background-color: #006094;
                        color: #fff;
                    }
                }

                .content-benefits-plan{
                    // border-top: 1px solid #d5d5d5;
                    width: 90%;
                    margin-top: 1.5em;
                    padding: 1em 0 0;
                    display: flex;
                    flex-direction: column;

                    >div{
                        display: flex;
                        align-items: center;
                        padding: .4em .5em;
                        border-bottom: 1px solid #d5d5d5;

                        svg{
                            margin-right: .5em;

                            &.fa-circle-xmark{
                                color: red;
                            }

                            &.fa-circle-check{
                                color: green;
                            }
                        }

                        p{
                            opacity: .8;
                            font-size: .85em;
                        }

                        &:last-of-type{
                            border-bottom: 0;
                        }
                    }
                }
            }
        }

        .info-billing-plan{
            margin-top: 7em;
            color: #333333;
            opacity: .8;
            font-size: .75em;
            width: 100%;

            p:first-of-type{
                margin-bottom: .5vh;
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    #page-planos{ 
        #section-planos-planos{
            padding: 15vh 5vw;
        }
    }
}

@media screen and (max-width: 1000px) {
    #page-planos{ 
        #section-planos-planos{
            padding: 15vh 3vw;

            ul{
                gap: 2em;
            }

            .info-billing-plan{
                width: 90%;
                text-align: justify;

                p:first-of-type{
                    margin-bottom: 1vh;
                }
            }
        }
    }
}

@media screen and (max-width: 900px) {
    #page-planos{
        #section-planos-planos{
            ul{
                width: 100%;
            }
        }
    }
}

@media screen and (max-width: 850px) {
    #page-planos{
        #section-planos-planos{
            ul{
                gap: 1.5em;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    #page-planos{
        #section-planos-planos{
            ul{
                flex-direction: column;
                align-items: center;

                li{
                    width: 50%;
                    margin-top: 5vh;

                    &:first-of-type{
                        margin-top: 0;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 700px) {
    #page-planos{
        #section-planos-planos{ 
            ul{
                li{
                    width: 65%;
                }
            }
        }
    }
}

@media screen and (max-width: 600px) {
    #page-planos{
        #section-planos-planos{ 
            ul{
                li{
                    width: 75%;
                }
            }
        }
    }
}

@media screen and (max-width: 500px) {
    #page-planos{
        #section-planos-planos{ 
            ul{
                li{
                    width: 80%;
                }
            }
        }
    }
}