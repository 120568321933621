@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

#page-home{
    width: 100%;

    #section-home-introduct{
        height: 100vh;
        width: 100%;
        // background-color: red;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 7.5%;
        // background: rgb(245,245,245);
        // background: linear-gradient(180deg, rgba(245,245,245,1) 0%, rgba(245,245,245,1) 60%, rgba(255,255,255,1) 100%);
        background: rgb(245,245,245);
        background: linear-gradient(180deg, rgba(245,245,245,1) 0%, rgba(245,245,245,1) 60%, rgba(250,250,250,1) 100%);

        >div{
            width: 55%;
            padding-right: 5%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            h1{
                font-size: 3.5em;
                line-height: 1.25;
                font-weight: 600;
                margin-bottom: .5em;
                color: #333333;
    
                span{
                    font-weight: 700;
                    color: #0071ae;
                }
            }

            h4{
                font-weight: 400;
                opacity: .8;
                margin-bottom: 2em;
            }

            >a{
                padding: .8em 2em;
                background-color: #0071ae;
                color: #fff !important;
                border-radius: 50px;
                transition: 0.3s ease all;
                font-size: .85em;

                span{
                    margin-left: .5em;
                    left: 0;
                    transition: .3s ease all;
                    position: relative;
                }

                &:hover{
                    background-color: #006094;
                    color: #fff;

                    span{
                        left: .3em;
                    }
                }
            }
        }

        img{
            width: 45%;
        }
    }

    #section-home-about{
        width: 100%;
        padding: 15vh 10vw;
        background: rgb(250,250,250);
        background: linear-gradient(180deg, rgba(250,250,250,1) 0%, rgba(255,255,255,1) 30%, rgba(255,255,255,1) 100%);
        display: flex;
        align-items: center;
        justify-content: space-between;

        .image-section-about{
            width: 40%;
            position: relative;

            img{
                width: 100%;
                border-radius: 30px 125px 30px 125px;
            }

            .object-image1{
                width: 6em;
                height: 3em;
                background-color: #00a6c7;
                border-radius: 35px 4px;
                position: absolute;
                bottom: 6%;
                left: 0;
            }

            .object-image2{
                width: 3em;
                height: 1.5em;
                background-color: #00a6c7;
                border-radius: 35px;
                position: absolute;
                top: 8%;
                right: 2%;
            }
        }

        .texts-section-about{
            width: 55%;
            display: flex;
            flex-direction: column;

            h2, h3{
                text-align: center;
            }

            h2{
                span{
                    color: #006094;
                }
            }

            ul{
                display: flex;
                flex-direction: column;
                margin-top: 2em;
                width: 100%;
                // margin: .5em auto 0;

                li{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: 1.2em;

                    >div{
                        width: 9%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        aspect-ratio: 1;
                        border-radius: 50%;
                        // background-color: #f5f5f5;
                        background-color: #00a6c7;
                        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
                    }

                    svg{
                        color: #fff;
                        // color: #0071ae;
                        // padding: .5em;
                        font-size: 1.5em;
                        border-radius: 5px;
                        font-weight: 600;
                        // color: #fff;
                        // margin-right: .8em;
                    }

                    p{
                        width: 86.5%;
                        text-align: justify;
                        font-size: 1em;
                        font-weight: 400;
                        opacity: .8;
                        color: #333333;
                    }
                }
            }
        }
    }

    #section-home-companys{
        padding: 15vh 10vw;
        background-color: #f5f5f5;

        h2{
            span{
                color: #006094;
            }
        }

        ul{
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            width: 90%;
            margin: 0 auto;

            li{
                display: flex;
                align-items: center;
                border-radius: 8px;
                background-color: #00a6c7;
                margin: .3em .5em;
                padding: .5em 1.2em;
                color: #fff;

                svg{
                    margin-right: .7em;
                }
            }
        }
    }

    #section-home-features{
        width: 100%;
        padding: 15vh 10vw 20vh;
        background-color: #fff;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: space-between;

        >div{
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            width: 45%;

            h2{
                font-size: 2.4em;
                margin-bottom: .7em;
                span{
                    color: #006094;
                }
            }

            h3{
                text-align: justify;
            }
    
            >a{
                padding: .7em 1.7em;
                background-color: #0071ae;
                color: #fff !important;
                border-radius: 50px;
                margin-top: 1.5em;
                transition: 0.3s ease all;
                font-size: .85em;
                z-index: 2;
    
                span{
                    margin-left: .5em;
                    left: 0;
                    transition: .3s ease all;
                    position: relative;
                }
    
                &:hover{
                    background-color: #006094;
                    color: #fff;
    
                    span{
                        left: .3em;
                    }
                }
            }
        }

        ul{
            margin-top: 3em;
            width: 45%;
            display: flex;
            flex-direction: column;
            // display: grid;
            // grid-template-columns: repeat(3, 1fr);
            // grid-template-rows: 1fr;
            // grid-column-gap: 3em;

            li{
                padding: 1em 1.5em;
                display: flex;
                align-items: center;
                justify-content: space-between;
                // box-shadow: 0px 4px 24px 0 rgba(29, 140, 242, 0.16);
                transition: all .5s ease;
                // border-radius: 8px;
                margin-bottom: 1.5em;
                background-color: #f5f5f5;
                border-right: 4px solid #006094;
                box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

                svg{
                    font-size: 2em;
                    color: #006094;
                    // margin-bottom: .5em;
                }

                >div{
                    display: flex;
                    flex-direction: column;
                    text-align: justify;
                    width: 100%;
                    padding: 0 3%;

                    h4{
                        color: #333333;
                        margin-bottom: .6em;
                        font-size: 1.1em;
                        font-weight: 600;
                        // text-align: center;
                    }
    
                    p{
                        font-size: .9em;
                        // line-height: 1.3;
                        opacity: 0.8;
                        // text-align: center;
                    }
                }

                // &:hover{
                //     box-shadow: 0px 4px 24px 0 rgba(29, 140, 242, 0.32);
                // }
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    #page-home {
        #section-home-about{
            .texts-section-about{
                ul li div svg{
                    font-size: 1.3em;
                }
            }
        }

        #section-home-features {
            ul{
                grid-column-gap: 1em
            }
        }
    }
}

@media screen and (max-width: 1000px) {
    #page-home {
        #section-home-introduct{
            flex-direction: column-reverse !important;
            justify-content: flex-start;

            img{
                width: 40%;
                margin-bottom: 2vh;
            }

            >div{
                width: 100%;
                padding-right: 0%;

                h1{
                    font-size: 3em;
                }
            }
        }

        #section-home-about{
            padding: 15vh 5vw;
        }

        #section-home-features{
            padding: 15vh 5vw;
        }
    }
}

// @media screen and (max-width: 900px) {
//     #page-home {
//         #section-home-features{
//             ul{
//                 display: flex !important;
//                 // flex-wrap: wrap;
//                 // justify-content: space-around;
//                 // grid-column-gap: 0;
//                 // grid-row-gap: 3vh;

//                 // li{
//                 //     width: 47%;
//                 // }
//             }
//         }
//     }
// }

@media screen and (max-width: 850px) {
    #page-home {
        #section-home-about{
            .image-section-about{
                display: none;
            }

            .texts-section-about{
                width: 100%;
                align-items: center;

                ul{
                    width: 70%;
                }
            }
        }
    }
}

@media screen and (max-width: 800px) {
    #page-home {
        #section-home-features{
            padding: 15vh 10vw;
            flex-direction: column;

            div{
                width: 100%;

                h3{
                    text-align: center;
                }

                a{
                    display: none;
                }
            }

            ul{
                width: 100%;

                li{
                    border-right: 0;
                    border-left: 4px solid #006094;
                }
            }
        }
    }
}

@media screen and (max-width: 750px) {
    #page-home{
        h2{
            line-height: 1.1;
            margin-bottom: 5vh;
        }

        #section-home-introduct{
            justify-content: center;

            img{
                display: none;
            }

            div{
                text-align: center;
                align-items: center;

                h4{
                    margin-bottom: 3.5em;
                }
            }
        }

        #section-home-about{
            padding: 15vh 7.5vw;

            .texts-section-about{
                ul{
                    width: 100%;
                    // margin-top: 2em;
                }

                h2{
                    text-align: center;
                }

                h3{
                    text-align: justify;
                }
            }
        }

        #section-home-companys{
            padding: 12vh 5vw 20vh;
        }
    }
}

@media screen and (max-width: 700px) {
    #page-home {
        #section-home-introduct{
            >div {
                h1{
                    font-size: 2.8em;
                }
            }
        }
    }
}

@media screen and (max-width: 650px) {
    #page-home{
        section{
            h2{
                font-size: 2.2em;
            }

            h3{
                font-size: .9em;
            }
        }
    }
}

@media screen and (max-width: 600px) {
    #page-home{
        #section-home-about{
            .texts-section-about{
                ul li {
                    div {
                        width: 15%;
                    }

                    p{
                        width: 80%;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 550px) {
    #page-home{
        #section-home-companys{
            padding: 12vh 5vw 12vh;

            ul{
                width: 100% !important;

                li{
                    font-size: .8em;
                }
            }
        }

        #section-home-about{
            .texts-section-about{
                ul {
                    margin-top: 1em;
                }
            }
        }
    }
}