@keyframes slidein {
    0% {
    //   transform: translate(0vw, 0);
        left: 0;
    }
  
    100% {
        left: calc(100vw - 10%);
    //   transform: translate(90.7vw, 0);
    }
}

#loading-bar{
    width: 100vw;
    height: .2em;
    position: relative;

    #barra-animada{
        width: 10%;
        background-color: #060735;
        height: 100%;
        position: absolute;
        animation: .5s linear 0s infinite normal slidein;
    }
}