#page-contato{
    #section-contact-contato{
        width: 100%;
        padding: 15vh 10vw;
        display: flex;
        flex-direction: column;
        align-items: center;

        h1{
            font-size: 3em;
            line-height: 1.25;
            font-weight: 600;
            margin: 10vh 0 0.5em;
            color: #333333;
            text-align: center;

            span{
                font-weight: 700;
                color: #0071ae;
            }
        }

        h4{
            font-size: 1.15em;
            font-weight: 400;
            opacity: 0.8;
            margin-bottom: 5em;
            text-align: center;
        }

        >div{
            width: 90%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;

            form{
                width: 75%;
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                background-color: #f5f5f5;
                border-radius: 8px;
                padding: 2em 10em 2em 2em;

                >div{
                    display: flex;
                    flex-direction: column;
                    margin-bottom: 1.5em;

                    label{
                        font-size: .8em;
                        color: #565656;
                        margin-bottom: .4em;
                    }
                }

                .footer-form{
                    width: 100%;
                    display: flex;
                    align-items: center;
                    margin: 1em 0 0;

                    >div{
                        margin-bottom: 1em;
                    }

                    p{
                        font-size: .9em;
                    }

                    button{
                        margin-top: 1em;
                        background-color: #00b300;
                        transition: 0.5s;
                        color: #fff;
                        padding: 0.45em 1.6em;
                        white-space: nowrap;
                        cursor: pointer;
                        border-radius: 4px;
    
                        &:hover{
                            background-color: #17bd17;
                        }
                    }
                }
            }

            #other-contacts{
                position: absolute;
                right: 0;
                padding: 3em 1em;
                color: #fff;
                width: 35%;
                border-radius: 8px;
                background-color: #060736;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                h5{
                    font-weight: 500;
                    font-size: 1.1em;
                    margin-bottom: .5em;
                }

                >p{
                    opacity: .8;
                    font-size: .8em;
                    text-align: center;
                    margin-bottom: 2.5em;
                }

                ul{
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    li{
                        display: flex;
                        align-items: center;
                        margin-bottom: .7em;
                        opacity: .8;

                        >svg{
                            margin-right: 1em;
                        }

                        >p{
                            text-align: center;
                            font-size: .8em;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    #page-contato{
        #section-contact-contato{
            padding: 15vh 5vw;
        }
    }
}

@media screen and (max-width: 1000px) {
    #page-contato{
        #section-contact-contato{
            >div{
                width: 100%;

                #other-contacts{
                    width: 40%;
                }
            }
        }
    }
}

@media screen and (max-width: 800px) {
    #page-contato{
        #section-contact-contato{
            padding: 15vh 5vw 2vh;

            h4{
                margin-bottom: 3em;
            }

            >div{
                flex-direction: column;

                form{
                    width: 85%;
                    padding: 2em 2em 7em;
                }

                #other-contacts{
                    position: relative;
                    top: -4em;
                    width: 65%;
                    padding: 2em 1em;
                }
            }
        }
    }
}

@media screen and (max-width: 650px) {
    #page-contato{
        #section-contact-contato{
            >div{
                form{
                    width: 95%;
                }

                #other-contacts{
                    width: 70%;
                }
            }
        }
    }
}

@media screen and (max-width: 550px) {
    #page-contato{
        #section-contact-contato{
            >div{
                form{
                    width: 100%;
                }

                #other-contacts{
                    width: 75%;
                }
            }
        }
    }
}

@media screen and (max-width: 500px) {
    #page-contato{
        #section-contact-contato{
            >div{
                #other-contacts{
                    width: 85%;
                }
            }
        }
    }
}