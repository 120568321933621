footer{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #0071ae;
    padding: 0 5%;

    .container-footer-top{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 4em 0 2em;
        // border-bottom: 2px solid #ffffff80;

        .content-footer-company{
            width: 25%;
    
            >img{
                width: 55%;
                margin-bottom: .8em;
            }

            >div{
                display: flex;
                flex-direction: column;

                h5{
                    color: #fff;
                    font-weight: 500;
                    line-height: 2;
                    font-size: .9em;
                }

                h6{
                    color: #ffffffb3;
                    font-weight: 400;
                    font-size: .75em;
                    line-height: 1.7;
                }
            }
        }

        .content-footer-links{
            display: flex;
            width: 60%;
            padding: 1.5em 0 1.5em 5%;
            border-left: 2px solid #ffffff80;

            ul{
                width: 35%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                h3{
                    font-weight: 500;
                    font-size: 1em;
                    opacity: .9;
                    margin-bottom: 1em;
                    color: #fff;
                    width: 100%;
                }

                li{
                    cursor: pointer;
                    color: #ffffffb3;
                    font-weight: 300;
                    font-size: .9em;
                    margin-bottom: .2em;

                    &:hover{
                        color: #060736;
                    }
                }
            }

            >div{
                width: 30%;
                display: flex;
                flex-direction: column;
                
                ul{
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;

                    li{
                        width: clamp(35px, 15%, 30%);

                        svg{
                            font-size: 1.5em;
                        }
                    }
                }
            }
        }
    }

    .container-footer-bottom{
        width: 100%;
        // border-top: 1px solid #ffffff80;
        padding-bottom: 1em;
        font-weight: 400;
        color: #ffffffb3;
        font-size: .75em;
        text-align: center;
    }
}

@media screen and (max-width: 950px) {
    footer{
        padding: 0 1%;
    }
}

@media screen and (max-width: 800px) {
    footer{
        .content-footer-company{
            width: 30% !important;
            padding-right: 5%;
        }

        .content-footer-links{
            flex-wrap: wrap;
            justify-content: space-between;

            >ul{
                width: 49% !important;
            }

            >div{
                width: 100% !important;
                margin-top: 5vh;

                >ul{
                    text-align: center;
                }
            }
        }
    }
}

@media screen and (max-width: 750px) {
    footer{
        .container-footer-top{
            flex-direction: column-reverse;

            .content-footer-company{
                width: 50% !important;
                padding-right: 0;
                text-align: center;
            }

            .content-footer-links{
                width: 75%;
                padding-left: 0;
                border-left: none;
                border-bottom: 2px solid rgba(255, 255, 255, 0.5019607843);
                margin-bottom: 10vh;

                >div{
                    ul{
                        justify-content: space-between;
                    }
                }
            }
        }
    }
}