header{
    padding: 3.5vh 2vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 98;
    transition: .2s ease all;

    &.sticky{
        padding: 2vh 2vw;
        background-color: #fff;
        box-shadow: 0px 4px 4px rgba(29,140,242,0.08);
    }

    >a{
        max-width: 150px;
        display: flex;

        img{
            width: 100%;
        }
    }

    nav{
        display: flex;
        align-items: center;
        font-size: .9em;
        font-weight: 300;

        ul{
            display: flex;

            li{
                margin: 0 1em;
                transition: .2s ease all;

                &:hover{
                    color: #0071ae;
                }

                &:last-of-type{
                    color: #0071ae;
                    font-weight: 500;

                    &:hover{
                        color: #006094;
                    }
                }
            }
        }

        >a{
            margin-left: 1em;
            padding: .5em 1.5em;
            background-color: #0071ae;
            // background-color: #00a6c7;
            // background-color: #060736;
            color: #fff !important;
            border-radius: 50px;
            transition: .3s ease all;

            span{
                margin-left: .5em;
                left: 0;
                transition: .3s ease all;
                position: relative;
            }

            &:hover{
                background-color: #006094;
                color: #fff;

                span{
                    left: .3em;
                }
            }
        }
    }
}

#btn-sou-parceiro{
    position: fixed;
    z-index: 10;
    bottom: 5vh;
    right: 3vw;
    background-color: #fff;
    padding: 1vh 2vw;
    border-radius: 100px;
    // color: #fff;
    box-shadow: 0px 4px 4px rgba(29, 140, 242, 0.08);
}

@media screen and (max-width: 950px) {
    header{
        >a{
            width: 150px;
        }

        nav{
            ul{
                li{
                    &:not(:last-of-type){
                        display: none;
                    }
                }
            }

            >a{
                font-size: .9em;
            }
        }
    }
}

@media screen and (max-width: 550px) {
    header{
        nav{
            ul{
                li{
                    display: none;
                }
            }
        }
    }
}