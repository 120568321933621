// Scroll

::-webkit-scrollbar { /* Tamanho */
    width: 6px;
    height: 8px;
}
  
::-webkit-scrollbar-track { /* Fundo */
    background: #fff;
}

::-webkit-scrollbar-thumb { /* Background */
    background: #c1c1c1;
}

::-webkit-scrollbar-thumb:hover { /* Hover */
    background: #060736;
}

body{
    background-color: #fff !important;
}

*{
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}

a, a:hover, a:visited, a:focus, a:active{
    text-decoration: none;
    color: inherit;
    outline: 0;
    cursor: pointer;
}

ul, li{
    margin: 0;
    padding: 0;
    list-style: none;
}
    
p, h1, h2, h3, h4, h5, h6{
    margin: 0;
    padding: 0;
}

button{
    outline: 0;
    border: 0;
    cursor: pointer;

    &:focus-visible{
        outline: none;
    }
}

input, textarea{
    border: 0;
    border-bottom: 1px solid #cacaca;
    background-color: transparent;
    transition: border .2s ease;
    font-weight: 400;
    font-size: .9em;
    resize: none;

    &:focus-visible{
        outline: none;
    }

    &:focus{
        border-bottom: 1px solid #333333;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
        transition: background-color 5000000s ease-in-out 0s;
        font-weight: 500;
        font-size: .9em;
    }
}

select{
    border: 0;
    border-bottom: 1px solid #cacaca;
    background-color: transparent;
    transition: border 0.2s ease;
    font-weight: 400;
    font-size: 0.9em;
    padding: 0.1em 0;

    &:focus-visible{
        outline: none;
    }

    &:focus{
        border-bottom: 1px solid #333333;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
        transition: background-color 5000000s ease-in-out 0s;
        font-weight: 500;
        font-size: .9em;
    }
}

section{
    h2{
        font-size: 2.5em;
        font-weight: 700;
        color: #333333;
        text-align: center;
        line-height: 1.5;
    }

    h3{
        text-align: center;
        font-weight: 400;
        font-size: 1em;
        opacity: 0.8;
        margin: .5em 0 2em;
    }

    &.section-cta{
        width: 100%;
    
        >div{
            padding: 12vh 10vw;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            background-color: #060736;
            position: relative;
    
            &:before {
                background: inherit;
                top: 0;
                content: '';
                display: block;
                height: 100%;
                left: 0;
                position: absolute;
                right: 0;
                transform: skewY(2deg);
                transform-origin: 100% 0%;
            }
    
            h2{
                z-index: 2;
                color: #fff !important;
                text-align: center !important;
                font-size: 2em !important;
            }
    
            h3{
                z-index: 2;
                color: #fff;
                margin-bottom: 3.5em;
    
                span{
                    opacity: 1;
                }
            }
    
            >a{
                padding: .8em 2em;
                background-color: #0071ae;
                color: #fff !important;
                border-radius: 50px;
                transition: 0.3s ease all;
                font-size: .85em;
                z-index: 2;
    
                span{
                    margin-left: .5em;
                    left: 0;
                    transition: .3s ease all;
                    position: relative;
                }
    
                &:hover{
                    background-color: #006094;
                    color: #fff;
    
                    span{
                        left: .3em;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 850px) {
    section:not(#section-contratar-forms):not(#section-home-introduct){
        >h1{
            font-size: 2.8em;
        }

        >h4{
            font-size: 1.1em;
        }
    }
}

@media screen and (max-width: 700px) {
    section:not(#section-contratar-forms):not(#section-home-introduct){
        >h1{
            font-size: 2.5em;
        }

        >h4{
            font-size: 1em;
        }
    }
}

@media screen and (max-width: 550px) {
    section{
        &.section-cta{
            >div{
                h2{
                    font-size: 1.8em !important;
                }
            }
        }
    }
}