#page-faq{
    #section-faq-faq{
        width: 100%;
        padding: 15vh 10vw;
        display: flex;
        flex-direction: column;
        align-items: center;

        h1{
            font-size: 3em;
            line-height: 1.25;
            font-weight: 600;
            margin: 10vh 0 0.5em;
            color: #333333;
            text-align: center;

            span{
                font-weight: 700;
                color: #0071ae;
            }
        }

        h4{
            font-size: 1.15em;
            font-weight: 400;
            opacity: 0.8;
            margin-bottom: 5em;
            text-align: center;
        }

        #categories-faq{
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 1.5em;

            >div{
                padding: .5em 1em;
                background-color: #8ad8e6;
                margin: 0 .7em;
                border-radius: 100px;
                user-select: none;
                cursor: pointer;
                display: flex;
                align-items: center;
                transition: all .4s;

                &.category-faq-active{
                    background-color: #0071ae;
                    color: #fff;
                }

                svg{
                    margin-right: .8em;
                }

                p{
                    font-size: .9em;
                }
            }
        }

        #search-container{
            border-radius: 100px;
            width: 60%;
            padding: .8em 1em;
            border: 1px solid #cacaca;
            transition: border 0.2s ease;

            input{
                border: 0;
                width: 95%;
                padding-left: 1em;

                &::placeholder{
                    font-weight: 300;
                }
            }

            svg{
                width: 5%;
                cursor: pointer;
            }
        }

        #faq-container{
            margin-top: 5em;
            width: 65%;

            ul{
                width: 100%;

                li{
                    width: 100%;
                    padding: 1em 2em;
                    border-radius: 8px;
                    background-color: #f5f5f5;
                    margin-bottom: 1em;
                    cursor: pointer;

                    &:last-of-type{
                        margin-bottom: 0;
                    }

                    .title-question-faq{
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        h6{
                            font-weight: 600;
                            font-size: 1em;
                            width: 95%;
                        }
                    }

                    .content-answer{
                        width: 100%;
                        max-height: 0;
                        font-size: .9em;
                        opacity: .8;
                        cursor: pointer;
                        display: flex;
                        overflow: hidden;
                        visibility: hidden;
                        transition: all .5s cubic-bezier(.25,.8,.25,1);
    
                        p{
                            margin-top: 1em;
                            text-align: justify;
                        }
                    }
    
                    .content-answer.show-content-answer{
                        height: auto;
                        max-height: 100px;
                        display: flex;
                        visibility: visible;
                        overflow: hidden;
                        transition: all 1s cubic-bezier(.25,.8,.25,1);
                    }
                }
            }
        }

        h5{
            margin-top: 5em;
            font-weight: 500;
            font-size: 1.05em;
            opacity: 0.8;
            text-align: center;

            a{
                color: #0071ae;
            }
        }
    }
}

@media screen and (max-width: 1000px) {
    #page-faq{
        #section-faq-faq{
            padding: 15vh 5vw;

            h1{
                font-size: 2.7em;
            }

            h4{
                font-size: 1em;
            }
        }
    }
}

@media screen and (max-width: 800px) {
    #page-faq{
        #section-faq-faq{
            #faq-container{
                width: 80%;
                margin-top: 8vh;
            }
        }
    }
}

@media screen and (max-width: 650px) {
    #page-faq{
        #section-faq-faq{
            padding: 15vh 2vw;

            #faq-container{
                width: 90%;
            }

            h5{
                font-size: 0.95em;
            }

            #search-container{
                width: 80%;
            }
        }
    }
}

@media screen and (max-width: 600px) {
    #page-faq{
        #section-faq-faq{
            #categories-faq{
                justify-content: space-around;
                row-gap: 2vh;
    
                >div{
                    width: 40%;
                    justify-content: center;
                }
            }
    
            #faq-container{
                width: 95%;
            }
        }
    }
}

@media screen and (max-width: 500px) {
    #page-faq{
        #section-faq-faq{
            #categories-faq{    
                >div{
                    width: 55%;
                }
            }
        }
    }
}