.notification{
    max-width: 95%;
    padding: 1em 2em 1em 1em;
    border-radius: 5px;
    margin-bottom: .8em;
    box-shadow: 0 4px 4px rgba(0,0,0,.12), 0 0 10px rgba(0,0,0,.06);
    background-color: #fff;
    position: relative;
    display: flex;
    align-items: center;

    &:last-of-type{
        margin: 0;
    }

    h3{
        font-weight: 500;
        font-size: 1em;
        margin-left: .5em;
    }

    svg{
        font-size: 1.2em;

        &.fa-circle-xmark{
            color: red;
        }

        &.fa-circle-check{
            color: green;
        }

        &.fa-circle-exclamation{
            color: orange;
        }

        &.fa-circle-info{
            color: blue;
        }
    }

    .icon-close-notification{
        margin-left: .5em;
        font-size: 1em;
        opacity: .6;
        cursor: pointer;
        position: absolute;
        top: .2em;
        right: .4em;
        
        &:hover{
            opacity: 1;
        }
    }

    p{
        margin-left: 1em;
        opacity: .7;
        font-size: .85em;
    }
}

.area-notification{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    top: 2.5vh;
    width: 100%;
    z-index: 99;
    // padding: 1.5em;
}