#page-contratar{
    display: flex;
    align-items: center;
    height: 100vh;

    #section-contratar-image{
        width: 40%;
        height: 100vh;
        position: relative;
        padding: 12.5vh 0;

        img{
            height: 100%;
            max-width: 100%;
            // width: 70%;
            object-fit: cover;
            position: absolute;
            top: 0;
            left: 0;
        }

        h1{
            font-size: 1.85em;
            line-height: 1.25;
            font-weight: 600;
            color: #333333;
            text-align: center;

            span{
                font-weight: 700;
                color: #0071ae;
            }
        }
    }

    #section-contratar-forms{
        width: 60%;
        margin-top: calc(15vh / 2);
        max-height: 85vh;
        padding: 0 5em;
        display: flex;
        flex-direction: column;
        align-items: center;
        // justify-content: center;

        h1{
            font-size: 1.85em;
            line-height: 1.25;
            font-weight: 600;
            color: #333333;
            text-align: center;

            span{
                font-weight: 700;
                color: #0071ae;
            }
        }

        .info-steps-account{
            width: 70%;
            margin: 6vh 0 2vh;

            h2{
                font-size: 1.1em;
                color: #0071ae;
                font-weight: 500;
            }

            p{
                margin-top: 1vh;
                font-size: .75em;
                text-align: center;
                opacity: .9;
            }
        }

        .stages-create-account{
            width: 60%;
            margin-top: 7vh;
            display: flex;
            justify-content: space-between;

            >div{
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                width: calc(100% / 3);

                &.selected-stage-account{
                    svg{
                        opacity: 1 !important;
                    }
                }

                svg{
                    font-size: 1.2em;
                    color: #0071ae;

                    &.fa-circle-check{
                        opacity: 1;
                    }

                    &.fa-circle{
                        opacity: .3;
                    }
                }

                .bar-stage-create-account{
                    position: absolute;
                    // width: 42.5%;
                    width: calc(50% - (1.2em / 2));
                    height: 15%;
                    background-color: #0071ae;
                    opacity: .3;

                    &.bar-left-stage-account{
                        left: 0;
                    }

                    &.bar-right-stage-account{
                        right: 0;
                    }

                    &.bar-finish-stage-account{
                        opacity: 1;
                    }
                }

                // &::before{
                //     position: absolute;
                //     content: '';
                //     left: 0;
                //     width: 42.5%;
                //     background-color: #0071ae;
                //     height: 35%;
                // }

                // &::after{
                //     position: absolute;
                //     content: '';
                //     right: 0;
                //     width: 42.5%;
                //     background-color: #0071ae;
                //     height: 35%;
                // }
            }
        }

        form{
            width: 75%;
            margin-top: 5vh;
            display: flex;
            flex-wrap: wrap;
            overflow-y: auto;
            padding: 0 1em 1em;

            &:has(> h3){
                margin-top: 0;
            }

            >h3{
                font-size: .85em;
            }

            .campos-form{
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;

                h6{
                    font-weight: 400;
                    margin-bottom: 2vh;
                    color: #333333;
                    opacity: 0.8;
                    font-size: 0.75em;
                }

                .input-radio-account{
                    flex-direction: row;
                    justify-content: center;
                    border-radius: 100px;
                    user-select: none;
                    width: 100%;
                    margin-bottom: 6vh;
        
                    >div{
                        width: 40%;
                        // background-color: #d5d5d5;
                        border: 3px solid #0071ae;
                        padding: .2em .5em;
                        text-align: center;
                        cursor: pointer;
                        color: #333333;
                        font-size: .8em;
        
                        &:first-of-type{
                            border-radius: 100px 0 0 100px;
                            border-right: 0;
                        }
        
                        &:last-of-type{
                            border-radius: 0 100px 100px 0;
                            border-left: 0;
                        }
        
                        &.radio-account-active{
                            background-color: #0071ae;
                            cursor: auto;
                            color: #fff;
                        }
                    }
                }

                >div{
                    display: flex;
                    flex-direction: column;
                    margin-bottom: 1.5em;
    
                    label{
                        font-size: 0.8em;
                        color: #565656;
                        margin-bottom: 0.4em;
                    }

                    input{
                        &.invalid-box{
                            border-bottom: 2px solid red;
                        }
                    }
    
                    &:last-of-type{
                        margin-bottom: 5vh;
                    }

                    .radio-plans{
                        width: 100%;
                        margin-bottom: 2.5vh;

                        &:last-of-type{
                            margin: 0;
                        }

                        input{
                            display: none;

                            &:checked + label{
                                border: 2px solid #0071ae;
                                cursor: default;

                                svg{
                                    color: #0071ae;
                                }
                            }
                        }

                        label{
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            width: 100%;
                            padding: 1em 1.5em;
                            margin: 0;
                            border: 2px solid #d9d9d9;
                            border-radius: 6px;
                            cursor: pointer;
                            user-select: none;

                            &:hover{
                                border: 2px solid #0071ae;
                            }

                            .title-label-radio{
                                width: 100%;
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                margin-bottom: .5em;

                                h4{
                                    font-size: 1.2em;
                                    font-weight: 500;
                                }

                                svg{
                                    font-size: 1.3em;
                                }
                            }

                            >div:not(.title-label-radio){
                                display: flex;
                                align-items: flex-end;
                                margin-bottom: 1em;

                                h5{
                                    font-size: 1.3em;
                                    font-weight: 700;
                                    color: #000;
                                    opacity: .8;

                                    span{
                                        text-decoration: line-through;
                                        margin-right: .5em;
                                    }
                                }
    
                                p{
                                    font-weight: 500;
                                    opacity: .6;
                                    font-size: .75em;
                                    line-height: 2;
                                    // margin-left: .5em;
                                }
                            }

                            p{
                                font-size: 1em;
                            }

                            button{
                                color: #0071ae;
                                margin: 2vh auto 0;
                                background-color: transparent;
                                display: flex;
                                justify-content: center;
                                align-items: center;

                                &:hover{
                                    color: #005c8e;
                                }

                                svg{
                                    margin-right: .5em;
                                }
                            }

                            .content-details{
                                width: 100%;
                                max-height: 0;
                                // font-size: .9em;
                                // opacity: .8;
                                cursor: default;
                                display: flex;
                                flex-direction: column;
                                overflow: hidden;
                                visibility: hidden;
                                transition: all .5s cubic-bezier(.25,.8,.25,1);
            
                                li{
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-between;
                                    flex-direction: row-reverse;
                                    padding: .5em 1em;
                                    font-size: 1.1em;
                                    border-bottom: 1px solid #d5d5d5;

                                    &:first-of-type{
                                        margin-top: 1em;
                                    }

                                    &:last-of-type{
                                        border-bottom: 0;
                                    }

                                    svg{
                                        margin-right: .5em;
                                        font-size: 1.2em;

                                        &.fa-circle-check{
                                            color: green;
                                        }

                                        &.fa-circle-xmark{
                                            color: red;
                                        }
                                    }
                                }
                            }
            
                            .content-details.show-content-details{
                                height: auto;
                                max-height: 500px;
                                display: flex;
                                visibility: visible;
                                overflow: hidden;
                                transition: all 1s cubic-bezier(.25,.8,.25,1);
                            }
                        }
                    }
                }
            }

            .btn-toolbar{
                width: 100%;
                display: flex;
                justify-content: space-between;

                button{
                    padding: .5em 2em;
                    color: #fff;
                    border-radius: 8px;
                    transition: 0.3s ease all;

                    &.btn-avancar{
                        background-color: #27a12f;

                        span{
                            margin-left: .5em;
                            left: 0;
                        }

                        &:hover{
                            background-color: #3fab45;
    
                            span{
                                left: .3em;
                            }
                        }
                    }

                    &.btn-voltar{
                        background-color: #f44336;

                        span{
                            margin-right: .5em;
                            right: 0;
                        }

                        &:hover{
                            background-color: #b32616;
    
                            span{
                                right: .3em;
                            }
                        }
                    }
    
                    span{
                        transition: .3s ease all;
                        position: relative;
                    }
    
                    &:hover{
                        color: #fff;
                    }
                }
            }
        }

        .btn-login{
            margin-bottom: calc(6vh + 7.5vh);
            padding: .5em 2em;
            color: #fff;
            border-radius: 8px;
            transition: 0.3s ease all;
            font-size: 0.8315em;
            background-color: #0071ae;

            &:hover{
                background-color: #006094;
            }
        }

        img[alt="Logo BookingHub"]{
            position: absolute;
            top: 2em;
            width: clamp(160px, 30vw, 230px);
            display: none;
        }
    }

    .modal{
        background-color: rgba(0, 0, 0, 0.3);
        width: 100vw;
        height: 100%;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;

        .content-modal{
            width: clamp(450px, 30%, 90%);
            background-color: #fff;
            box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
            border-radius: 5px;
            padding: 1em;
            display: flex;
            flex-direction: column;
            align-items: center;
            position: relative;

            >svg{
                position: absolute;
                top: .5em;
                right: .8em;
                cursor: pointer;
            }

            h6{
                font-size: 1.1em;
                font-weight: 500;
                text-align: center;
                margin-bottom: .6em;
            }

            p{
                font-size: .85em;
                text-align: center;
                margin-bottom: 1.5em;

                span{
                    color: #0071ae;
                    font-weight: 600;
                }
            }

            form{
                display: flex;
                flex-direction: column;
                align-items: center;

                >div{
                    display: flex;
    
                    &:first-of-type{
                        justify-content: space-between;
                        width: 40%;
                        margin-bottom: 2em;
    
                        input{
                            width: calc(100% / 5);
                            text-align: center;
                            font-size: 1.5em;
                        }
                    }
    
                    &:last-of-type{
                        width: 80%;
                        justify-content: space-between;
    
                        button{
                            padding: .5em 1.5em;
                            border-radius: 8px;
                            transition: 0.3s ease all;
    
                            &:first-of-type{
                                background-color: transparent;
                                color: #333333;
    
                                &:hover{
                                    text-decoration: underline;
                                }

                                &:disabled{
                                    opacity: .5;
                                    cursor: not-allowed;

                                    &:hover{
                                        text-decoration: none;
                                    }
                                }
                            }
    
                            &:last-of-type{
                                background-color: #0071ae;
                                color: #fff;
    
                                &:hover{
                                    background-color: #005c8e;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1300px) {
    #page-contratar{
        #section-contratar-forms{
            padding: 0 2em;
        }
    }
}

@media screen and (max-width: 1100px) {
    #page-contratar{
        #section-contratar-forms{
            padding: 0;

            form{
                width: 85%;
            }
        }
    }
}

@media screen and (max-width: 900px) {
    #page-contratar{
        #section-contratar-image{
            display: none;
        }

        #section-contratar-forms{
            width: 100%;
            padding: 0 15%;

            img[alt="Logo BookingHub"]{
                display: block;
            }
        }
    }
}

@media screen and (max-width: 800px) {
    #page-contratar{
        #section-contratar-forms{
            padding: 0 12.5%;
        }
    }
}

@media screen and (max-width: 700px) {
    #page-contratar{
        #section-contratar-forms{
            padding: 0 10%;
        }
    }
}

@media screen and (max-width: 650px) {
    #page-contratar{
        #section-contratar-forms{
            padding: 0 5%;
        }
    }
}

@media screen and (max-width: 600px) {
    #page-contratar{
        #section-contratar-forms{
            padding: 0 0;

            h1{
                font-size: 1.75em;
            }
        }
    }
}

@media screen and (max-width: 550px) {
    #page-contratar{
        #section-contratar-forms{
            .stages-create-account{
                width: 75%;
            }
        }
    }
}

@media screen and (max-width: 500px) {
    #page-contratar{
        #section-contratar-forms{
            .stages-create-account{
                width: 80%;
            }

            form{
                .campos-form{
                    .input-radio-account{
                        >div{
                            width: 48%;
                        }
                    }

                    >div:has(#cnpj), >div:has(#razao_social), >div:has(#nome_empresa), >div:has(#telefone_empresa){
                        width: 100% !important;
                    }
                }
            }
        }
    }
}